import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IAddPatientCenter, IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* getCountry(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.countryService,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_COUNTRY_SUCCESS,
      Constants.GET_COUNTRY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_COUNTRY_FAILED, error })
  }
}

function* getPatient(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.patientService,
      api_key,
      action.page,
      action.size,
      action.patientsType,
      action.searchText,
      action.gammeIds,
      action.makerIds,
      action.sort,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PATIENT_SUCCESS,
      Constants.GET_PATIENT_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PATIENT_FAILED, error })
  }
}

function* getPatientsSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getPatientsSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PATIENTS_SUMMARY_SUCCESS,
      Constants.GET_PATIENTS_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PATIENTS_SUMMARY_FAILED, error })
  }
}

function* getProsthesisGammeRange(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.prosthesisGammeRangeService,
      api_key,
      action.page,
      action.size,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PROSTHESIS_GAMME_RANGE_SUCCESS,
      Constants.GET_PROSTHESIS_GAMME_RANGE_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PROSTHESIS_GAMME_RANGE_FAILED, error })
  }
}

function* postPatient(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addPatientService,
      api_key,
      action.patient,
    )

    yield call(
      parseResponse,
      response,
      Constants.POST_PATIENT_SUCCESS,
      Constants.POST_PATIENT_FAILED,
    )
    response.status === 200
      ? action.reject(response.data.error)
      : action.resolve(response.data.id)
  } catch (error) {
    yield put({ type: Constants.POST_PATIENT_FAILED, error })
  }
}

function* getPatientByID(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getPatientByIdService,
      api_key,
      action.id,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PATIENT_BY_ID_SUCCESS,
      Constants.GET_PATIENT_BY_ID_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PATIENT_BY_ID_FAILED, error })
  }
}

function* updatePatient(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updatePatientInfo,
      api_key,
      action.patient,
    )

    yield call(
      parseResponse,
      response,
      Constants.UPDATE_PATIENT_INFO_SUCCESS,
      Constants.UPDATE_PATIENT_INFO_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.UPDATE_PATIENT_INFO_FAILED, error })
  }
}

function* postNewPatientNote(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addPatientNoteService,
      api_key,
      action.note,
    )

    yield call(
      parseResponse,
      response,
      Constants.POST_NEW_NOTE_SUCCESS,
      Constants.POST_NEW_NOTE_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.POST_NEW_NOTE_FAILED, error })
  }
}

function* addPatientCenter(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  const getUserCenter = (state: RootState) => state.authReducer.company

  try {
    const api_key: string = yield select(getApiKey)
    const center: IAddPatientCenter = yield select(getUserCenter)

    const response: IResponseStatus = yield call(
      Services.addPatientCenter,
      api_key,
      action.id,
      center,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* postNewPatientStatus(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.changePatientStatusService,
      api_key,
      action.status,
    )

    yield call(
      parseResponse,
      response,
      Constants.POST_NEW_STATUS_SUCCESS,
      Constants.POST_NEW_STATUS_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.POST_NEW_STATUS_FAILED, error })
  }
}

function* addImplant(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addImplant,
      api_key,
      action.infos,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updatePatientDoctor(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updatePatientDoctor,
      api_key,
      action.patientId,
      action.doctor,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updatePatientHistory(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updatePatientHistory,
      api_key,
      action.patientId,
      action.history,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updatePatientTreatment(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updatePatientTreatment,
      api_key,
      action.patientId,
      action.treatment,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updateImplant(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updateImplant,
      api_key,
      action.infos,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* deleteImplant(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.deleteImplant,
      api_key,
      action.patientId,
      action.implantId,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updatePatientReasons(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updatePatientReasons,
      api_key,
      action.implantationId,
      action.reasons,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* getTransmitterReferences(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getTransmitterReferences,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_TRANSMITTER_REFERENCES_SUCCESS,
      '',
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* createTransmitter(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.createTransmitter,
      api_key,
      action.referenceId,
      action.serialNumber,
      action.method,
      action.transmitterId,
    )

    const succeed = response.status === 200 || response.status === 201

    yield call(parseResponse, response, '', '')
    succeed
      ? action.resolve(response.data.id)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* affectTransmitter(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.affectTransmitter,
      api_key,
      action.infos,
      action.method,
    )

    const succeed = response.status === 200 || response.status === 201

    yield call(parseResponse, response, '', '')
    succeed ? action.resolve() : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* postAgreement(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.postAgreement,
      api_key,
      action.transmitterId,
      action.file,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* deleteTransmitter(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.deleteTransmitter,
      api_key,
      action.transmitterId,
      action.affectationId,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* getStoredFile(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getStoredFile,
      api_key,
      action.id,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* postPatientPrescription(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.generatePerPatient,
      api_key,
      action.patientId,
    )
    yield call(
      parseResponse,
      response,
      Constants.POST_PATIENT_PRESCRIPTION_SUCCESS,
      Constants.POST_PATIENT_PRESCRIPTION_FAILED,
    )
    response.status === 200 || response.status === 201
      ? action.resolve(response.data)
      : action.reject(response.data.message)
  } catch (error) {
    yield put({
      type: '',
      error,
    })
  }
}

function* postCrmEnrollment(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.crmEnrollment,
      api_key,
      action.implantId,
    )
    yield call(
      parseResponse,
      response,
      Constants.POST_CRM_ENROLLMENT_SUCCESS,
      Constants.POST_CRM_ENROLLMENT_FAILED,
    )
    response.status === 200 || response.status === 201
      ? action.resolve(response.data)
      : action.reject(response.data.message)
  } catch (error) {
    yield put({
      type: '',
      error,
    })
  }
}
function* postCrmSend(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.crmSend,
      api_key,
      action.implantId,
    )
    yield call(
      parseResponse,
      response,
      Constants.POST_CRM_SEND_SUCCESS,
      Constants.POST_CRM_SEND_FAILED,
    )
    response.status === 200 || response.status === 201
      ? action.resolve(response.data)
      : action.reject(response.data.message)
  } catch (error) {
    yield put({
      type: '',
      error,
    })
  }
}

export const patientsSagas = [
  takeLatest(Constants.GET_COUNTRY, getCountry),
  takeLatest(Constants.GET_PATIENT, getPatient),
  takeLatest(Constants.GET_PATIENTS_SUMMARY, getPatientsSummary),
  takeLatest(Constants.GET_PROSTHESIS_GAMME_RANGE, getProsthesisGammeRange),
  takeLatest(Constants.POST_PATIENT, postPatient),
  takeLatest(Constants.GET_PATIENT_BY_ID, getPatientByID),
  takeLatest(Constants.UPDATE_PATIENT_INFO, updatePatient),
  takeLatest(Constants.POST_NEW_NOTE, postNewPatientNote),
  takeLatest(Constants.POST_NEW_STATUS, postNewPatientStatus),
  takeLatest(Constants.ADD_PATIENT_CENTER, addPatientCenter),
  takeLatest(Constants.ADD_PATIENT_IMPLANT, addImplant),
  takeLatest(Constants.UPDATE_PATIENT_DOCTOR, updatePatientDoctor),
  takeLatest(Constants.UPDATE_PATIENT_HISTORY, updatePatientHistory),
  takeLatest(Constants.UPDATE_PATIENT_TREATMENT, updatePatientTreatment),
  takeLatest(Constants.UPDATE_IMPLANT, updateImplant),
  takeLatest(Constants.DELETE_IMPLANT, deleteImplant),
  takeLatest(Constants.UPDATE_PATIENT_REASONS, updatePatientReasons),
  takeLatest(Constants.GET_TRANSMITTER_REFERENCES, getTransmitterReferences),
  takeLatest(Constants.CREATE_TRANSMITTER, createTransmitter),
  takeLatest(Constants.AFFECT_TRANSMITTER, affectTransmitter),
  takeLatest(Constants.POST_AGREEMENT, postAgreement),
  takeLatest(Constants.DELETE_TRANSMITTER, deleteTransmitter),
  takeLatest(Constants.GET_STORED_FILE, getStoredFile),
  takeLatest(Constants.POST_PATIENT_PRESCRIPTION, postPatientPrescription),
  takeLatest(Constants.POST_CRM_ENROLLMENT, postCrmEnrollment),
  takeLatest(Constants.POST_CRM_SEND, postCrmSend),
]
