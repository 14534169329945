import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { PropsFromReduxPatientInfo } from '../containers/PatientsInfoContainer'
import { useParams } from 'react-router-dom'
import Loader from '../../common/Loader'
import { toast } from 'react-toastify'
import SearchDoctorModal from './SearchDoctorModal'
import history from '../../../routes/history'
import {
  getHistoryName,
  getMakerImage,
  getTreatmentName,
  implantReasonSub,
  prosthesisGammeName,
  prosthesisRangeName,
} from '../../../utils/patientInfos'
import { ICreatePatient, IImplantationList, ITreatmentList } from '../reducers'
import dateformat from 'dateformat'
import { handleChangeSINumber } from '../../../utils/addPatient'
import ModalImplant from './Modals/ModalImplant'
import ModalNotes from './Modals/ModalNotes'
import ModalStatus from './Modals/ModalStatus'
import ModalHistory from './Modals/ModalHistory'
import { IHistoryList } from '../../../modules/patients/reducers'
import ModalTreatment from './Modals/ModalTreatment'
import ModalModifyImplant from './Modals/ModalModifyImplant'
import ModalModifyProsthesis from './Modals/ModalModifyProsthesis'
import ModalModifyIndications from './Modals/ModalModifyIndications'
import ModalModifyTransmitter from './Modals/ModalModifyTransmitter'
import { useSelector } from 'react-redux'
import { canAddModifyDeletePatientData } from '../../auth/selectors/authSelector'
import AlertsContainer from '../../alerts/containers/AlertsContainer'
import { PictureAsPdf } from '@mui/icons-material'
import PdfPreview from '../../../utils/PdfPreview'
import { REFACTOR_URL } from '../../../helpers/env'
import PrescriptionsContainer from '../../prescriptions/containers/PrescriptionsContainer'

const useStyles = makeStyles(() => ({
  paperCard: { borderRadius: '15px !important' },
  listCard: { padding: 0 },
  spanHeaderCard: { marginRight: '5px' },
  newNotePaper: {
    borderRadius: '15px !important',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface IPreviewPdfPosition {
  x: number
  y: number
  attachedFileId: number
}

const PatientInfoComponent = (props: PropsFromReduxPatientInfo) => {
  const initialPreviewPdfPosition: IPreviewPdfPosition = {
    x: 0,
    y: 0,
    attachedFileId: -1,
  }
  const [isLoading, setIsLoading] = useState(true)
  const [changePatientInfo, setChangePatientInfo] = useState(false)
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false)
  const [isOpenBgModal, setIsOpenBgModal] = useState(false)
  const [isOpenTreatModal, setIsOpenTreatModal] = useState(false)
  const [newImplantModal, setNewImplantModal] = useState(false)
  const [modifyImplantModal, setModifyImplantModal] = useState(false)
  const [modifyProsthesisModal, setModifyProsthesisModal] = useState(false)
  const [modifyIndicationsModal, setModifyIndicationsModal] = useState(false)
  const [modifyTransmitterModal, setModifyTransmitterModal] = useState(false)
  const [newStatusModal, setNewStatusModal] = useState(false)
  const [statusModalType, setStatusModalType] = useState<
    'STATUS' | 'PATHOLOGY'
  >('STATUS')
  const [selectedImplantation, setSelectedImplantation] =
    useState<IImplantationList>()
  const [doctorModal, setDoctorModal] = useState(false)
  const [sliceIndex, setSliceIndex] = useState(3)
  const [patient, setPatient] = useState<ICreatePatient>({
    gender: props.patientInfo?.gender ? props.patientInfo.gender : '',
    patientName: props.patientInfo?.patientName
      ? props.patientInfo.patientName
      : '',
    patientNameUsual: props.patientInfo?.patientNameUsual
      ? props.patientInfo.patientNameUsual
      : '',
    firstName: props.patientInfo?.firstName ? props.patientInfo.firstName : '',
    birthDay: props.patientInfo?.birthDay
      ? dateformat(props.patientInfo.birthDay, 'yyyy-mm-dd')
      : '',
    address: props.patientInfo?.address ? props.patientInfo.address : '',
    zipCode: props.patientInfo?.zipCode ? props.patientInfo.zipCode : '',
    city: props.patientInfo?.city ? props.patientInfo.city : '',
    socialInsuranceNumber: props.patientInfo?.socialInsuranceNumber
      ? props.patientInfo.socialInsuranceNumber
      : '',
    doctor: null,
    cpamCenter: props.patientInfo?.cpamCenter
      ? props.patientInfo.cpamCenter
      : '',
    phone: props.patientInfo?.phone ? props.patientInfo.phone : '',
    phone1: props.patientInfo?.phone1 ? props.patientInfo.phone1 : '',
    phone2: props.patientInfo?.phone2 ? props.patientInfo.phone2 : '',
    phone3: props.patientInfo?.phone3 ? props.patientInfo.phone3 : '',
    ald: props.patientInfo?.ald ? props.patientInfo.ald : false,
  })
  const [pdfPosition, setPdfPosition] = useState(initialPreviewPdfPosition)
  const [delayPdfHover, setDelayPdfHover] = useState(setTimeout(() => {}, 1))

  const params: { id: string } = useParams()

  const fetchDatas = async () => {
    setIsLoading(true)
    try {
      await props.getPatientByIdService(params.id)
    } catch {
      toast.error('Impossible de charger le patient')
      history.push('/patients')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPatient({
      gender: props.patientInfo?.gender ? props.patientInfo.gender : '',
      patientName: props.patientInfo?.patientName
        ? props.patientInfo.patientName
        : '',
      patientNameUsual: props.patientInfo?.patientNameUsual
        ? props.patientInfo.patientNameUsual
        : '',
      firstName: props.patientInfo?.firstName
        ? props.patientInfo.firstName
        : '',
      birthDay: props.patientInfo?.birthDay
        ? dateformat(props.patientInfo.birthDay, 'yyyy-mm-dd')
        : '',
      address: props.patientInfo?.address ? props.patientInfo.address : '',
      zipCode: props.patientInfo?.zipCode ? props.patientInfo.zipCode : '',
      city: props.patientInfo?.city ? props.patientInfo.city : '',
      socialInsuranceNumber: props.patientInfo?.socialInsuranceNumber
        ? props.patientInfo.socialInsuranceNumber
        : '',
      doctor: null,
      cpamCenter: props.patientInfo?.cpamCenter
        ? props.patientInfo.cpamCenter
        : '',
      phone: props.patientInfo?.phone ? props.patientInfo.phone : '',
      phone1: props.patientInfo?.phone1 ? props.patientInfo.phone1 : '',
      phone2: props.patientInfo?.phone2 ? props.patientInfo.phone2 : '',
      phone3: props.patientInfo?.phone3 ? props.patientInfo.phone3 : '',
      ald: props.patientInfo?.ald ? props.patientInfo.ald : false,
    })
  }, [changePatientInfo, props.patientInfo])

  useEffect(() => {
    fetchDatas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOpenBgModal,
    isOpenTreatModal,
    modifyImplantModal,
    newImplantModal,
    modifyProsthesisModal,
    modifyIndicationsModal,
    modifyTransmitterModal,
  ]) //eslint-disable-line

  const handleChangePatient = (type: string, value: string | boolean) => {
    setPatient({ ...patient, [type]: value })
  }

  const updatePatientInfos = async () => {
    setIsLoading(true)

    const copyPatient: ICreatePatient = { ...patient }

    if (copyPatient.phone2 === '') delete copyPatient.phone2
    if (copyPatient.phone3 === '') delete copyPatient.phone3

    try {
      await props.updatePatient({
        ...copyPatient,
        countryId: 1,
        birthDay: new Date(
          copyPatient.birthDay !== '' ? copyPatient.birthDay : '1970-01-01',
        ).toISOString(),
        id: parseInt(params.id),
      })
      if (copyPatient.doctor)
        await props.updatePatientDoctor(parseInt(params.id), copyPatient.doctor)
      fetchDatas()
    } catch {
      toast.error('Erreur lors de la modification du patient')
    } finally {
      toast.success('Patient modifié')
      setIsLoading(false)
      setChangePatientInfo(false)
    }
  }

  const handleDoctorSelection = (doctor: any) => {
    handleChangePatient('doctor', doctor.row)
    setDoctorModal(false)
  }

  const handleModifyImplant = (
    implantation: IImplantationList,
    type: 'implant' | 'prosthesis' | 'indications' | 'transmitter',
  ) => {
    setSelectedImplantation(implantation)
    if (type === 'implant') setModifyImplantModal(true)
    else if (type === 'prosthesis') setModifyProsthesisModal(true)
    else if (type === 'indications') setModifyIndicationsModal(true)
    else if (type === 'transmitter') setModifyTransmitterModal(true)
  }

  const onPdfEnter = (
    event: React.MouseEvent<HTMLDivElement>,
    fileId: number,
  ) => {
    setDelayPdfHover(
      setTimeout(() => {
        const height = window.innerHeight * 0.8
        const width = (height * 210) / 297

        const x =
          event.clientX - (width + 150) > 65
            ? event.clientX - (width + 150)
            : 65
        const y = 80
        setPdfPosition({
          x,
          y,
          attachedFileId: fileId,
        })
      }, 500),
    )
  }

  const onPdfLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    // in case icon is under pdf preview don't leave to avoid open close loop
    if (event.clientX < 65 || event.clientX > pdfPosition.x + 686) {
      clearTimeout(delayPdfHover)
      setPdfPosition(initialPreviewPdfPosition)
    }
  }

  const onClickPdf = (storedFileId: number) => {
    clearTimeout(delayPdfHover)
    setPdfPosition(initialPreviewPdfPosition)
    history.push('/full-pdf', {
      path: REFACTOR_URL,
      url: `stored_file/${storedFileId}`,
    })
  }
  const onGeneratePrescription = () => {
    setIsLoading(true)
    try {
      console.log(
        `will generate prescrition for patient ${props.patientInfo.id}`,
      )
      props.postPatientPrescription(props.patientInfo.id)
    } catch (e) {
    } finally {
      toast.success('Prescription initiée')
      setIsLoading(false)
    }
  }

  const onGenerateDocuments = () => {
    setIsLoading(true)
    try {
      const implantId = props.patientInfo.implantationList[0].id
      if (implantId) {
        console.log(`will generate documents for implant ${implantId}`)
        props.postCrmEnrollment(implantId)
      }
    } finally {
      toast.success('Documents générés')
      setIsLoading(false)
    }
  }

  const onSendDocuments = () => {
    setIsLoading(true)
    try {
      const implantId = props.patientInfo.implantationList[0].id
      if (implantId) {
        console.log(`will send documents for implant ${implantId}`)
        props.postCrmSend(implantId)
      }
    } finally {
      toast.success('Documents envoyés')
      setIsLoading(false)
    }
  }

  const hasPermissionToUpdatePatientData = useSelector(
    canAddModifyDeletePatientData,
  )

  const classes = useStyles()

  return (
    <>
      <SearchDoctorModal
        isOpen={doctorModal}
        setIsOpen={setDoctorModal}
        handleDoctorSelection={handleDoctorSelection}
        getDoctors={props.getDoctors}
        doctorType="FamilyDoctor"
        getCountries={props.getCountries}
        getDoctorAutocomplete={props.getDoctorAutocomplete}
        addDoctor={props.addDoctor}
      />

      <ModalHistory
        isOpen={isOpenBgModal}
        setIsOpen={setIsOpenBgModal}
        updateHistory={props.updatePatientHistory}
        patientInfo={props.patientInfo}
        updatePatient={props.updatePatient}
      />

      <ModalTreatment
        isOpen={isOpenTreatModal}
        setIsOpen={setIsOpenTreatModal}
        updateTreatment={props.updatePatientTreatment}
        patientInfo={props.patientInfo}
        updatePatient={props.updatePatient}
      />

      <ModalImplant
        classes={classes}
        isOpen={newImplantModal}
        setIsOpen={setNewImplantModal}
        addImplant={props.addImplant}
        setDoctorModal={setDoctorModal}
        patientId={parseInt(params.id)}
        center={props.user.company}
        getDoctors={props.getDoctors}
      />

      {selectedImplantation && (
        <ModalModifyImplant
          classes={classes}
          isOpen={modifyImplantModal}
          setIsOpen={setModifyImplantModal}
          updateImplant={props.updateImplant}
          setDoctorModal={setDoctorModal}
          patientId={parseInt(params.id)}
          implantation={selectedImplantation}
          center={props.user.company}
          getDoctors={props.getDoctors}
          deleteImplant={props.deleteImplant}
        />
      )}

      {selectedImplantation && (
        <ModalModifyProsthesis
          isOpen={modifyProsthesisModal}
          setIsOpen={setModifyProsthesisModal}
          getProsthesisReferences={props.getProsthesisReferences}
          selectedImplantation={selectedImplantation}
          updateProsthesis={props.updatePatientProsthesis}
          deleteProsthesis={props.deletePatientProsthesis}
          patientId={parseInt(params.id)}
        />
      )}

      {selectedImplantation && (
        <ModalModifyIndications
          isOpen={modifyIndicationsModal}
          setIsOpen={setModifyIndicationsModal}
          selectedImplantation={selectedImplantation}
          updateReasons={props.updatePatientReasons}
        />
      )}

      {selectedImplantation && (
        <ModalModifyTransmitter
          isOpen={modifyTransmitterModal}
          setIsOpen={setModifyTransmitterModal}
          selectedImplantation={selectedImplantation}
          getTransmitterReferences={props.getTransmitterReferences}
          transmitterReferences={props.transmitterReferences}
          createTransmitter={props.createTransmitter}
          affectTransmitter={props.affectTransmitter}
          postAgreement={props.postAgreement}
          deleteTransmitter={props.deleteTransmitter}
          patientId={params.id}
        />
      )}

      <ModalNotes
        isOpen={isOpenNoteModal}
        setIsOpen={setIsOpenNoteModal}
        patientID={props.patientInfo?.id ? props.patientInfo.id : 0}
        userID={props.user.id}
        postNewPatientNote={props.postNewPatientNote}
        getPatient={props.getPatientByIdService}
      />

      {props.patientInfo && newStatusModal && (
        <ModalStatus
          isOpen={newStatusModal}
          setIsOpen={setNewStatusModal}
          patient={props.patientInfo}
          userID={props.user.id}
          postNewPatientStatus={props.postNewPatientStatus}
          statusModalType={statusModalType}
          getPatientByIdService={props.getPatientByIdService}
        />
      )}

      <Loader open={isLoading} text="Chargement du patient" />
      {props.patientInfo ? (
        <>
          <div style={{ margin: '15px' }}>
            <Button
              variant="contained"
              className="button-base"
              onClick={() => window.history.back()}
              style={{ marginBottom: '10px' }}
            >
              Retour
            </Button>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  marginBottom: '10px',
                }}
              >
                <span className={classes.spanHeaderCard}>Patient(e)</span>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {hasPermissionToUpdatePatientData && (
                    <Button
                      type="submit"
                      variant="contained"
                      className="button-patient-info"
                      onClick={() => {
                        setStatusModalType('STATUS')
                        setNewStatusModal(true)
                      }}
                    >
                      Arrêter le suivi
                    </Button>
                  )}
                </div>
              </ListItem>
              <ListItem sx={{ justifyContent: 'space-around' }}>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      paddingLeft: '30px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div style={{ fontSize: '16px' }}>
                        Identifiant: {props.patientInfo?.id}
                      </div>
                      {hasPermissionToUpdatePatientData &&
                        (!changePatientInfo ? (
                          <IconButton
                            onClick={() => setChangePatientInfo(true)}
                          >
                            <BorderColorIcon />
                          </IconButton>
                        ) : (
                          <div style={{ display: 'flex' }}>
                            <Button
                              variant="contained"
                              className="cancel-button"
                              sx={{
                                backgroundColor: '#438894',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 16px',
                                marginRight: '5px',
                              }}
                              onClick={() => setChangePatientInfo(false)}
                            >
                              Annuler
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: '#84c2ce',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 16px',
                              }}
                              onClick={updatePatientInfos}
                            >
                              Enregister
                            </Button>
                          </div>
                        ))}
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Sexe:</span>
                        {changePatientInfo ? (
                          <RadioGroup
                            sx={{ marginLeft: '10px' }}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={patient.gender}
                            onChange={(_, value) =>
                              handleChangePatient('gender', value)
                            }
                          >
                            <FormControlLabel
                              value="F"
                              checked={patient.gender === 'F'}
                              control={
                                <Radio
                                  sx={{
                                    color:
                                      patient.gender === 'F'
                                        ? '#84c2ce !important'
                                        : '',
                                  }}
                                />
                              }
                              label="Féminin"
                            />
                            <FormControlLabel
                              value="M"
                              checked={patient.gender === 'M'}
                              control={
                                <Radio
                                  sx={{
                                    color:
                                      patient.gender === 'M'
                                        ? '#84c2ce !important'
                                        : '',
                                  }}
                                />
                              }
                              label="Masculin"
                            />
                          </RadioGroup>
                        ) : (
                          <TextField
                            sx={{ marginLeft: '10px' }}
                            value={
                              patient.gender === 'F' ? 'Féminin' : 'Masculin'
                            }
                            variant="standard"
                            disabled={!changePatientInfo}
                            inputProps={{
                              sx: {
                                WebkitTextFillColor: '#000000DE !important',
                              },
                            }}
                            InputProps={{
                              disableUnderline: !changePatientInfo,
                            }}
                          />
                        )}
                      </Box>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Nom de naissance:
                        </span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.patientName}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('patientName', e.target.value)
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Nom utilisé:</span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.patientNameUsual}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient(
                              'patientNameUsual',
                              e.target.value,
                            )
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Prénom:</span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.firstName}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('firstName', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Date de naissance:
                        </span>
                        <TextField
                          sx={{ marginLeft: '10px' }}
                          type="date"
                          variant="standard"
                          value={patient.birthDay}
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(event) =>
                            handleChangePatient('birthDay', event.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Adresse:</span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.address}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('address', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Code postal:</span>
                        <TextField
                          inputProps={{
                            maxLength: 5,
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          sx={{ marginLeft: '10px' }}
                          value={patient.zipCode}
                          variant="standard"
                          disabled={!changePatientInfo}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('zipCode', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Ville:</span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.city}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('city', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>Téléphone: </span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.phone}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('phone', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Numéro de sécurité sociale:
                        </span>
                        <TextField
                          inputProps={{
                            maxLength: 21,
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.socialInsuranceNumber}
                          variant="standard"
                          disabled={!changePatientInfo}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(event) =>
                            handleChangeSINumber(
                              event.target.value,
                              handleChangePatient,
                            )
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Caisse d'affiliation:
                        </span>
                        <TextField
                          sx={{ marginLeft: '10px', width: '60%' }}
                          value={patient.cpamCenter}
                          variant="standard"
                          disabled={!changePatientInfo}
                          inputProps={{
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          onChange={(e) =>
                            handleChangePatient('cpamCenter', e.target.value)
                          }
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Médecin traitant:
                        </span>
                        <TextField
                          InputProps={{ disableUnderline: !changePatientInfo }}
                          inputProps={{
                            style: {
                              cursor: changePatientInfo ? 'pointer' : 'default',
                            },
                            sx: { WebkitTextFillColor: '#000000DE !important' },
                          }}
                          sx={{
                            cursor: changePatientInfo ? 'pointer' : 'default',
                            marginLeft: '10px',
                            width: '60%',
                          }}
                          type="text"
                          variant="standard"
                          disabled={!changePatientInfo}
                          value={
                            patient.doctor
                              ? `${patient.doctor.firstName} ${patient.doctor.userName}`
                              : `${props.patientInfo?.doctorFirstName && props.patientInfo?.doctorName ? `${props.patientInfo?.doctorFirstName} ${props.patientInfo?.doctorName}` : ''}`
                          }
                          onClick={() => {
                            if (changePatientInfo) setDoctorModal(true)
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <span style={{ color: '#00000090' }}>
                          Patient en ALD:
                        </span>
                        <FormControlLabel
                          label=""
                          control={
                            <Checkbox
                              sx={{
                                color: changePatientInfo
                                  ? '#84c2ce !important'
                                  : '#00000090 !important',
                              }}
                            />
                          }
                          labelPlacement="start"
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            margin: '0',
                          }}
                          checked={patient.ald === true}
                          disabled={!changePatientInfo}
                          onChange={() =>
                            handleChangePatient('ald', !patient.ald)
                          }
                        />
                      </Grid>
                    </Box>

                    <Paper elevation={4} sx={{ margin: 3, width: '80%' }}>
                      <Box
                        sx={{
                          padding: '16px',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          border: '3px black',
                          color: '#84c2ce',
                          fontWeight: 'bold',
                        }}
                      >
                        {'Centre:'}
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '15px',
                        }}
                      >
                        <span>
                          {props.patientInfo?.centers[0]?.companyName
                            ? props.patientInfo?.centers[0]?.companyName
                            : ''}
                        </span>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Paper elevation={4} sx={{ margin: 3, width: '80%' }}>
                      <Box
                        sx={{
                          padding: '16px',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          border: '3px black',
                        }}
                      >
                        <span style={{ fontWeight: 'bold', color: '#84c2ce' }}>
                          Antécédent(s):
                        </span>
                        {hasPermissionToUpdatePatientData && (
                          <IconButton onClick={() => setIsOpenBgModal(true)}>
                            <BorderColorIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Divider />
                      {props.patientInfo?.historyList &&
                      props.patientInfo.historyList.length > 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                          }}
                        >
                          {props.patientInfo.historyList.map(
                            (history: IHistoryList, i: number) => (
                              <span key={'hist' + i}>
                                {getHistoryName(history)}
                              </span>
                            ),
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                          }}
                        >
                          <span>Aucun antécédent</span>
                        </Box>
                      )}
                      {props.patientInfo &&
                        props.patientInfo.fev.toString() &&
                        props.patientInfo.fev.toString() !== '-1' &&
                        props.patientInfo.fev.toString() !== '0' && (
                          <>
                            <Divider />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '15px',
                              }}
                            >
                              <span>
                                Fraction d'Ejection du Ventricule gauche:{' '}
                                {props.patientInfo.fev}%
                              </span>
                            </Box>
                          </>
                        )}
                      {props.patientInfo &&
                        props.patientInfo.historyComment && (
                          <>
                            <Divider />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '15px',
                              }}
                            >
                              <span>
                                <span style={{ fontWeight: 'bold' }}>
                                  Commentaires:
                                </span>{' '}
                                {props.patientInfo?.historyComment}
                              </span>
                            </Box>
                          </>
                        )}
                    </Paper>
                    <Paper elevation={4} sx={{ margin: 3, width: '80%' }}>
                      <Box
                        sx={{
                          padding: '16px',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span style={{ fontWeight: 'bold', color: '#84c2ce' }}>
                          Traitement(s):
                        </span>

                        {hasPermissionToUpdatePatientData && (
                          <IconButton onClick={() => setIsOpenTreatModal(true)}>
                            <BorderColorIcon />
                          </IconButton>
                        )}
                      </Box>
                      <Divider />
                      {props.patientInfo?.treatmentList &&
                      props.patientInfo.treatmentList.length > 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                          }}
                        >
                          {props.patientInfo.treatmentList.map(
                            (treatment: ITreatmentList, i: number) => (
                              <span key={'treat' + i}>
                                {getTreatmentName(treatment)}
                              </span>
                            ),
                          )}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '15px',
                          }}
                        >
                          <span>Aucun traitement</span>
                        </Box>
                      )}
                      {props.patientInfo &&
                        props.patientInfo.treatmentComment && (
                          <>
                            <Divider />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '15px',
                              }}
                            >
                              <span>
                                <span style={{ fontWeight: 'bold' }}>
                                  Commentaires:
                                </span>{' '}
                                {props.patientInfo.treatmentComment}
                              </span>
                            </Box>
                          </>
                        )}
                    </Paper>
                  </Grid>
                </Grid>
              </ListItem>
            </Paper>
          </div>
          <div style={{ margin: '15px' }}>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <span className={classes.spanHeaderCard}>Implantations</span>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {hasPermissionToUpdatePatientData && (
                    <Button
                      type="submit"
                      variant="contained"
                      className="button-patient-info"
                      onClick={() => setNewImplantModal(true)}
                    >
                      Ajouter une implantation
                    </Button>
                  )}
                </div>
              </ListItem>
              {props.patientInfo?.implantationList?.length &&
              props.patientInfo.implantationList.length !== 0 ? (
                <>
                  {props.patientInfo.implantationList.map(
                    (implantation: any) => (
                      <>
                        <ListItem
                          key={implantation.id}
                          sx={{ display: 'flex', alignItems: 'start' }}
                        >
                          <Grid
                            container
                            direction="column"
                            sx={{ width: '100%', padding: '15px' }}
                          >
                            <Paper elevation={4} sx={{ width: '100%' }}>
                              <Box
                                sx={{
                                  padding: '16px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    color: '#84c2ce',
                                  }}
                                >
                                  Implantation:
                                </span>

                                {hasPermissionToUpdatePatientData && (
                                  <IconButton
                                    onClick={() =>
                                      handleModifyImplant(
                                        implantation,
                                        'implant',
                                      )
                                    }
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                )}
                              </Box>
                              <Divider />
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  padding: '15px',
                                  paddingBottom: '5px',
                                }}
                              >
                                <span>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Date:
                                  </span>{' '}
                                  {new Date(
                                    implantation.implantationDate,
                                  ).toLocaleDateString()}
                                </span>
                                <span style={{ marginLeft: '20px' }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Centre:
                                  </span>{' '}
                                  {implantation.center.company_name}
                                </span>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  padding: '15px',
                                  paddingTop: '5px',
                                }}
                              >
                                <div style={{ marginBottom: '5px' }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Cardiologue implanteur:
                                  </span>{' '}
                                  {implantation.cardioImplant
                                    ? `${
                                        implantation.cardioImplant.userName
                                          ? implantation.cardioImplant.userName
                                          : ''
                                      }${
                                        implantation.cardioImplant.firstName
                                          ? ' ' +
                                            implantation.cardioImplant.firstName
                                          : ''
                                      }`
                                    : 'Non défini'}
                                </div>
                                <div style={{ marginBottom: '5px' }}>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Cardiologue traitant:
                                  </span>{' '}
                                  {implantation.cardioAttent
                                    ? `${
                                        implantation.cardioAttent.userName
                                          ? implantation.cardioAttent.userName
                                          : ''
                                      }${
                                        implantation.cardioAttent.firstName
                                          ? ' ' +
                                            implantation.cardioAttent.firstName
                                          : ''
                                      }`
                                    : 'Non défini'}
                                </div>
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>
                                    Cardiologue facturant la télésurveillance:
                                  </span>{' '}
                                  {implantation.cardioBilling
                                    ? `${
                                        implantation.cardioBilling.userName
                                          ? implantation.cardioBilling.userName
                                          : ''
                                      }${
                                        implantation.cardioBilling.firstName
                                          ? ' ' +
                                            implantation.cardioBilling.firstName
                                          : ''
                                      }`
                                    : 'Non défini'}
                                </div>
                              </Box>
                            </Paper>
                          </Grid>
                          <Grid
                            container
                            direction="column"
                            sx={{ width: '100%', padding: '15px' }}
                          >
                            <Paper elevation={4} sx={{ width: '100%' }}>
                              <Box
                                sx={{
                                  padding: '16px',
                                  alignItems: 'center',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    color: '#84c2ce',
                                  }}
                                >
                                  Prothèse:
                                </span>

                                {hasPermissionToUpdatePatientData && (
                                  <IconButton
                                    onClick={() =>
                                      handleModifyImplant(
                                        implantation,
                                        'prosthesis',
                                      )
                                    }
                                  >
                                    <BorderColorIcon />
                                  </IconButton>
                                )}
                              </Box>
                              <Divider />
                              {implantation.prosthesisObject ? (
                                <>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      padding: '15px',
                                      paddingBottom: '5px',
                                    }}
                                  >
                                    <span>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Prothèse:
                                      </span>{' '}
                                      {implantation.prosthesisObject?.reference
                                        ?.prosthesisGamme?.string_key
                                        ? prosthesisGammeName(
                                            implantation.prosthesisObject
                                              .reference.prosthesisGamme
                                              .string_key,
                                          )
                                        : 'Non défini'}
                                    </span>
                                    <span style={{ marginLeft: '20px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Segment:
                                      </span>{' '}
                                      {implantation.prosthesisObject?.reference
                                        ?.prosthesisRange?.string_key
                                        ? prosthesisRangeName(
                                            implantation.prosthesisObject
                                              .reference.prosthesisRange
                                              .string_key,
                                          )
                                        : 'Non défini'}
                                    </span>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      padding: '5px 15px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span style={{ fontWeight: 'bold' }}>
                                        Marque:{' '}
                                      </span>{' '}
                                      {implantation.prosthesisObject?.reference
                                        ?.maker?.company?.company_name ? (
                                        <div className="img-maker-patient">
                                          {getMakerImage(
                                            implantation.prosthesisObject
                                              ?.reference?.maker?.company
                                              ?.company_name,
                                          )}
                                        </div>
                                      ) : (
                                        'Non défini'
                                      )}
                                    </span>
                                    <span>
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          marginLeft: '20px',
                                        }}
                                      >
                                        Modèle:
                                      </span>{' '}
                                      {implantation.prosthesisObject?.reference
                                        ?.maker?.prosthesis_model
                                        ? implantation.prosthesisObject
                                            .reference.maker.prosthesis_model
                                        : 'Non défini'}
                                    </span>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      padding: '15px',
                                      paddingTop: '5px',
                                    }}
                                  >
                                    <span>
                                      <span style={{ fontWeight: 'bold' }}>
                                        Référence:
                                      </span>{' '}
                                      {implantation.prosthesisObject?.reference
                                        ?.maker?.reference_name
                                        ? implantation.prosthesisObject
                                            .reference.maker.reference_name
                                        : 'Non défini'}
                                    </span>
                                    <span style={{ marginLeft: '20px' }}>
                                      <span style={{ fontWeight: 'bold' }}>
                                        N° de série:
                                      </span>{' '}
                                      {implantation.prosthesisObject
                                        ?.serialNumber
                                        ? implantation.prosthesisObject
                                            ?.serialNumber
                                        : 'Non défini'}
                                    </span>
                                  </Box>
                                </>
                              ) : (
                                <></>
                              )}
                            </Paper>
                          </Grid>
                        </ListItem>
                        {implantation.prosthesisObject?.serialNumber && (
                          <>
                            <ListItem
                              sx={{ display: 'flex', alignItems: 'end' }}
                            >
                              <Grid
                                container
                                direction="column"
                                sx={{ with: '50%', padding: '15px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  {hasPermissionToUpdatePatientData && (
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="button-base"
                                      onClick={() => onGeneratePrescription()}
                                    >
                                      Générer une prescription
                                    </Button>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                container
                                direction="column"
                                sx={{ with: '50%', padding: '15px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-center',
                                  }}
                                >
                                  {hasPermissionToUpdatePatientData && (
                                    <>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        className="button-base"
                                        disabled={
                                          implantation.enrollment?.length
                                        }
                                        onClick={() => onGenerateDocuments()}
                                      >
                                        Générer les documents
                                      </Button>
                                      {implantation.enrollment?.length > 0 &&
                                        implantation.enrollment.map(
                                          (enrollment: any) => (
                                            <div
                                              onMouseEnter={(event) =>
                                                onPdfEnter(
                                                  event,
                                                  enrollment.file.id,
                                                )
                                              }
                                              onMouseLeave={(event) =>
                                                onPdfLeave(event)
                                              }
                                              onClick={() =>
                                                onClickPdf(enrollment.file.id)
                                              }
                                              style={{ margin: '5px' }}
                                            >
                                              <PictureAsPdf
                                                sx={{
                                                  color: 'red',
                                                  fontSize: '20px',
                                                }}
                                              />
                                              <p
                                                style={{
                                                  margin: 0,
                                                  fontWeight: 'bold',
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                    color: 'gray',
                                                  }}
                                                >
                                                  {enrollment.file.display_name}
                                                </span>
                                              </p>
                                            </div>
                                          ),
                                        )}
                                    </>
                                  )}
                                </div>
                              </Grid>
                              <Grid
                                container
                                direction="column"
                                sx={{ with: '50%', padding: '15px' }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {hasPermissionToUpdatePatientData && (
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      className="button-base"
                                      disabled={
                                        implantation.enrollmentRequestSend
                                      }
                                      onClick={() => onSendDocuments()}
                                    >
                                      Envoyer les documents
                                    </Button>
                                  )}
                                </div>
                              </Grid>
                            </ListItem>
                          </>
                        )}

                        {implantation.prosthesisObject?.reference
                          ?.prosthesisGamme && (
                          <>
                            <ListItem
                              sx={{ display: 'flex', alignItems: 'start' }}
                            >
                              <Grid
                                container
                                direction="column"
                                sx={{ width: '100%', padding: '15px' }}
                              >
                                <Paper elevation={4} sx={{ width: '100%' }}>
                                  <Box
                                    sx={{
                                      padding: '16px',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#84c2ce',
                                      }}
                                    >
                                      Indications:
                                    </span>

                                    {hasPermissionToUpdatePatientData && (
                                      <IconButton
                                        onClick={() =>
                                          handleModifyImplant(
                                            implantation,
                                            'indications',
                                          )
                                        }
                                      >
                                        <BorderColorIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                  <Divider />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      padding: '15px',
                                    }}
                                  >
                                    {implantation.implantReasons
                                      .filter(
                                        (reason: any) => !reason.deleted_at,
                                      ) // Filter reasons without a `deletedAt`
                                      .map((reason: any, i: number) => (
                                        <span key={'reas' + i}>
                                          {reason.reason_id
                                            ? implantReasonSub(reason.reason_id)
                                            : ''}
                                        </span>
                                      ))}
                                  </Box>
                                </Paper>
                              </Grid>
                              <Grid
                                container
                                direction="column"
                                sx={{ width: '100%', padding: '15px' }}
                              >
                                <Paper elevation={4} sx={{ width: '100%' }}>
                                  <Box
                                    sx={{
                                      padding: '16px',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        color: '#84c2ce',
                                      }}
                                    >
                                      Télé-transmetteur:
                                    </span>

                                    {hasPermissionToUpdatePatientData && (
                                      <IconButton
                                        onClick={() =>
                                          handleModifyImplant(
                                            implantation,
                                            'transmitter',
                                          )
                                        }
                                      >
                                        <BorderColorIcon />
                                      </IconButton>
                                    )}
                                  </Box>
                                  <Divider />
                                  {implantation.transmitterAffectation && (
                                    <>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          padding: '15px',
                                          paddingBottom: '5px',
                                        }}
                                      >
                                        <span>
                                          <span style={{ fontWeight: 'bold' }}>
                                            N° série télétransmetteur:
                                          </span>{' '}
                                          {implantation.transmitterAffectation
                                            .transmitter?.serial_number
                                            ? implantation
                                                .transmitterAffectation
                                                .transmitter.serial_number
                                            : 'Non défini'}
                                        </span>
                                        <span style={{ marginLeft: '20px' }}>
                                          <span style={{ fontWeight: 'bold' }}>
                                            Distribué:
                                          </span>{' '}
                                          {implantation.transmitterAffectation
                                            .delivered_by_center
                                            ? 'Remis par le centre'
                                            : 'À livrer chez le patient'}
                                        </span>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          padding: '5px 15px',
                                        }}
                                      >
                                        <span>
                                          <span style={{ fontWeight: 'bold' }}>
                                            Date:
                                          </span>{' '}
                                          {implantation.transmitterAffectation
                                            .delivery_date
                                            ? new Date(
                                                implantation.transmitterAffectation.delivery_date,
                                              ).toLocaleDateString()
                                            : 'Non défini'}
                                        </span>
                                        <span style={{ marginLeft: '20px' }}>
                                          <span style={{ fontWeight: 'bold' }}>
                                            Consentement signé:
                                          </span>{' '}
                                          {implantation.transmitterAffectation
                                            .agreement
                                            ? 'Oui'
                                            : 'Non'}
                                        </span>

                                        <span style={{ marginLeft: '20px' }}>
                                          {implantation.transmitterAffectation
                                            .agreement_file ? (
                                            <div
                                              onMouseEnter={(event) => {
                                                onPdfEnter(
                                                  event,
                                                  Number(
                                                    implantation
                                                      .transmitterAffectation
                                                      .agreement_file.id,
                                                  ),
                                                )
                                              }}
                                              onMouseLeave={(event) => {
                                                onPdfLeave(event)
                                              }}
                                              onClick={() => {
                                                onClickPdf(
                                                  Number(
                                                    implantation
                                                      .transmitterAffectation
                                                      .agreement_file.id,
                                                  ),
                                                )
                                              }}
                                            >
                                              <PictureAsPdf
                                                sx={{
                                                  color: 'red',
                                                  fontSize: '20px',
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            'pas de fichier'
                                          )}
                                        </span>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          padding: '15px',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        <span>
                                          <span style={{ fontWeight: 'bold' }}>
                                            Date de la dernière transmission:
                                          </span>{' '}
                                          {implantation.transmitterAffectation
                                            .last_transmission_date
                                            ? new Date(
                                                implantation.transmitterAffectation.last_transmission_date,
                                              ).toLocaleDateString()
                                            : 'Non défini'}
                                        </span>
                                      </Box>
                                    </>
                                  )}
                                </Paper>
                              </Grid>
                            </ListItem>
                          </>
                        )}
                        {props.patientInfo.implantationList.length > 1 &&
                        implantation !==
                          props.patientInfo.implantationList[
                            props.patientInfo.implantationList.length - 1
                          ] ? (
                          <div
                            style={{
                              backgroundColor: '#84c2ce20',
                              height: '8px',
                            }}
                          >
                            <Divider />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ),
                  )}
                </>
              ) : (
                <>
                  <Grid
                    item
                    sx={{
                      padding: '10px 20px',
                    }}
                  >
                    Aucune implantation
                  </Grid>
                </>
              )}
            </Paper>
          </div>
          <div style={{ margin: '15px' }}>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <span className={classes.spanHeaderCard}>Prescriptions</span>
              </ListItem>
              <Box sx={{ padding: '10px 20px', paddingBottom: '125px' }}>
                <PrescriptionsContainer
                  patientId={props.patientInfo.id}
                ></PrescriptionsContainer>
              </Box>
            </Paper>
          </div>
          <div style={{ margin: '15px' }}>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <span className={classes.spanHeaderCard}>Pathologie</span>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {hasPermissionToUpdatePatientData && (
                    <Button
                      type="submit"
                      variant="contained"
                      className="button-patient-info"
                      onClick={() => {
                        setStatusModalType('PATHOLOGY')
                        setNewStatusModal(true)
                      }}
                    >
                      Changer la pathologie
                    </Button>
                  )}
                </div>
              </ListItem>
              <Box>
                {props.patientInfo.statusList &&
                props.patientInfo.statusList.length > 0 ? (
                  <>
                    {[...props.patientInfo.statusList]
                      .reverse()
                      .map((status: any, i: number) => (
                        <Box key={'stat' + i}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '10px 20px',
                            }}
                          >
                            <span style={{ marginTop: '10px' }}>
                              {(status.user?.firstName
                                ? 'De: ' + status.user.firstName
                                : ' ') +
                                ' le: ' +
                                new Date(status.createdAt).toLocaleDateString()}
                            </span>
                            <span>{status.statusType}</span>
                            <span style={{ marginBottom: '10px' }}>
                              {'Commentaire: ' +
                                (status.comment === null
                                  ? 'Aucun'
                                  : status.comment)}
                            </span>
                          </Box>
                          {i !== props.patientInfo?.statusList.length - 1 && (
                            <Divider />
                          )}
                        </Box>
                      ))}
                  </>
                ) : (
                  <Box sx={{ padding: '10px 20px' }}>Aucune pathologie</Box>
                )}
              </Box>
            </Paper>
          </div>
          <div style={{ margin: '15px' }}>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <span className={classes.spanHeaderCard}>Notes</span>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {hasPermissionToUpdatePatientData && (
                    <Button
                      onClick={() => setIsOpenNoteModal(true)}
                      variant="contained"
                      className="button-patient-info"
                    >
                      Ajouter une note
                    </Button>
                  )}
                </div>
              </ListItem>
              <Box>
                {props.patientInfo?.noteList &&
                props.patientInfo.noteList.length > 0 ? (
                  <>
                    {[...props.patientInfo.noteList]
                      .reverse()
                      .slice(0, sliceIndex)
                      .map((note: any, i: number) => (
                        <Box key={'note' + i}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              padding: '10px 20px',
                            }}
                          >
                            <span style={{ marginTop: '10px' }}>
                              {(note.user?.firstName
                                ? 'De: ' + note.user.firstName
                                : '') +
                                ' le: ' +
                                new Date(note.createdAt).toLocaleDateString()}
                            </span>
                            <span style={{ marginBottom: '10px' }}>
                              {note.note}
                            </span>
                          </Box>
                          {i !== props.patientInfo?.noteList.length - 1 && (
                            <Divider />
                          )}
                        </Box>
                      ))}
                    {sliceIndex < props.patientInfo.noteList.length && (
                      <Box sx={{ padding: '10px' }}>
                        <Button
                          sx={{ color: '#84c2ce' }}
                          onClick={() => setSliceIndex(sliceIndex + 3)}
                        >
                          Voir plus
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Box sx={{ padding: '10px 20px' }}>Aucune note</Box>
                )}
              </Box>
            </Paper>
          </div>
          <div style={{ margin: '15px' }}>
            <Paper elevation={2} className={classes.paperCard}>
              <ListItem
                alignItems="flex-start"
                className="home-card-header home-card-row"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}
              >
                <span className={classes.spanHeaderCard}>Alerts</span>
              </ListItem>
              <Box sx={{ padding: '10px 20px', paddingBottom: '125px' }}>
                <AlertsContainer patientId={props.patientInfo.id} />
              </Box>
            </Paper>
          </div>
        </>
      ) : (
        <div> No data found</div>
      )}
      {pdfPosition.attachedFileId !== -1 && (
        <PdfPreview
          onClose={() => {
            clearTimeout(delayPdfHover)
            setPdfPosition(initialPreviewPdfPosition)
          }}
          style={{
            position: 'fixed',
            top: pdfPosition.y,
            left: pdfPosition.x,
            zIndex: 1000,
            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '2px',
            borderRadius: '3px',
          }}
          getPDF={() => {
            return props.getStoredFile(Number(pdfPosition.attachedFileId))
          }}
        />
      )}
    </>
  )
}

export default PatientInfoComponent
