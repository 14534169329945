import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Box,
  Grid,
} from '@mui/material'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { ICreatePatient } from '../reducers'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SearchDoctorModal from './SearchDoctorModal'
import { patientSchema } from '../../../schemas'
import { handleChangeSINumber } from '../../../utils/addPatient'
import Loader from '../../../modules/common/Loader'
import history from './../../../routes/history'
import {
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'

interface IProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  postAddPatient: (patient: ICreatePatient) => Promise<number>
  getDoctors: (size: number, page: number) => Promise<unknown>
  addPatientCenter: (id: number) => Promise<unknown>
  doctorId: number
  getDoctorAutocomplete: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getCountries: () => Promise<unknown>
  addDoctor: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
}

export default function ResponsiveDialog(props: IProps) {
  const handleClose = () => {
    props.setIsOpen(false)
  }

  const [doctorModal, setDoctorModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [patient, setPatient] = useState<ICreatePatient>({
    gender: 'F',
    patientName: '',
    patientNameUsual: '',
    firstName: '',
    birthDay: '',
    address: '',
    zipCode: '',
    city: '',
    socialInsuranceNumber: '',
    doctor: null,
    phone: '',
    ald: false,
    cpamCenter: '',
    phone1: '+33 ',
    phone2: '',
    phone3: '',
    doctorId: props.doctorId,
  })

  const [isCompleted, setIsCompleted] = useState(false)

  const fieldsValidator = async () => {
    ;(await patientSchema.validate(patient))
      ? setIsCompleted(true)
      : setIsCompleted(false)
  }

  useEffect(() => {
    fieldsValidator()
  }, [patient]) //eslint-disable-line

  const handleChangePatient = (type: string, value: any) => {
    setPatient({ ...patient, [type]: value })
  }

  const RequiredField = () => {
    return <span style={{ color: 'red', fontSize: '16px' }}>*</span>
  }

  const InvoicingField = () => {
    return <span style={{ color: 'limegreen', fontWeight: 'bold' }}>€</span>
  }

  const handleDoctorSelection = (doctor: any) => {
    handleChangePatient('doctor', doctor.row)
    setDoctorModal(false)
  }

  const handleSave = async () => {
    setIsLoading(true)
    const copyPatient: ICreatePatient = { ...patient }
    if (copyPatient.phone2 === '') delete copyPatient.phone2
    if (copyPatient.phone3 === '') delete copyPatient.phone3
    try {
      await patientSchema.validate(copyPatient)
      props
        .postAddPatient({
          ...copyPatient,
          birthDay: new Date(
            copyPatient.birthDay !== '' ? copyPatient.birthDay : '1970-01-01',
          ).toISOString(),
          countryId: 1, //copyPatient.country.id
          doctorId: copyPatient.doctor ? copyPatient.doctor.id : undefined,
        })
        .then((patientId: number) => {
          toast.success('Patient enregistré')
          history.push('/patients/' + patientId)
          handleClose()
        })
        .catch((e) => toast.error(e))
    } catch (err: any) {
      toast.error(err.errors[0])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <Loader text="Enregistrement du patient" open={isLoading} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '20px',
          }}
        >
          {"Ajout d'un nouveau patient"}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            height: 'calc(100vh - 134px)',
          }}
        >
          <FormControl sx={{ height: '100%' }}>
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <p
                  style={{
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    color: 'grey',
                  }}
                >
                  Champs obligatoires : <RequiredField />
                </p>

                <p
                  style={{
                    marginLeft: '20px',
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    color: 'grey',
                  }}
                >
                  Champs pour la facturation : <InvoicingField />
                </p>
              </div>
              <div>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sexe
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={patient.gender}
                  onChange={(_, value) => handleChangePatient('gender', value)}
                >
                  <FormControlLabel
                    value="F"
                    checked={patient.gender === 'F'}
                    control={<Radio sx={{ color: '#84c2ce !important' }} />}
                    label="Femme"
                  />
                  <FormControlLabel
                    value="M"
                    checked={patient.gender === 'M'}
                    control={<Radio sx={{ color: '#84c2ce !important' }} />}
                    label="Homme"
                  />
                </RadioGroup>
              </div>
            </div>

            <div style={{ width: '650px' }}>
              <TextField
                sx={{ width: '50%', margin: '8px 0', paddingRight: '5px' }}
                fullWidth
                margin="dense"
                label={
                  <>
                    Nom de naissance <RequiredField /> <InvoicingField />
                  </>
                }
                type="text"
                variant="standard"
                value={patient.patientName}
                onChange={(event) =>
                  handleChangePatient(
                    'patientName',
                    event.target.value.match(/^(\s*|[aA-zZÜ-ü -]+)$/)
                      ? event.target.value
                      : patient.patientName,
                  )
                }
              />
              <TextField
                sx={{ width: '50%', margin: '8px 0', paddingRight: '5px' }}
                fullWidth
                margin="dense"
                label={<>Nom utilisé</>}
                type="text"
                variant="standard"
                value={patient.patientNameUsual}
                onChange={(event) =>
                  handleChangePatient(
                    'patientNameUsual',
                    event.target.value.match(/^(\s*|[aA-zZÜ-ü -]+)$/)
                      ? event.target.value
                      : patient.patientNameUsual,
                  )
                }
              />
              <TextField
                sx={{ width: '50%', margin: '8px 0' }}
                fullWidth
                margin="dense"
                label={
                  <>
                    Prénom <RequiredField /> <InvoicingField />
                  </>
                }
                type="text"
                variant="standard"
                value={patient.firstName}
                onChange={(event) =>
                  handleChangePatient(
                    'firstName',
                    event.target.value.match(/^(\s*|[aA-zZÜ-ü -]+)$/)
                      ? event.target.value
                      : patient.firstName,
                  )
                }
              />
              <br />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '8px',
                }}
              >
                <FormLabel>
                  Date de naissance <RequiredField /> <InvoicingField />
                </FormLabel>
                <TextField
                  sx={{ width: '50%', marginBottom: '8px' }}
                  margin="dense"
                  type="date"
                  variant="standard"
                  value={patient.birthDay}
                  onChange={(event) =>
                    handleChangePatient('birthDay', event.target.value)
                  }
                />
              </div>
            </div>
            <div>
              <TextField
                margin="dense"
                id="address"
                label={
                  <>
                    Adresse <RequiredField />
                  </>
                }
                fullWidth
                type="text"
                variant="standard"
                value={patient.address}
                onChange={(event) =>
                  handleChangePatient('address', event.target.value)
                }
              />
              <br />
              <TextField
                inputProps={{ maxLength: 5 }}
                sx={{ width: '50%', margin: '8px 0px', paddingRight: '5px' }}
                margin="dense"
                fullWidth
                label={
                  <>
                    Code postal <RequiredField />
                  </>
                }
                type="text"
                variant="standard"
                value={patient.zipCode}
                onChange={(event) =>
                  handleChangePatient(
                    'zipCode',
                    event.target.value.match(/^(^$|\d+)$/)
                      ? event.target.value
                      : patient.zipCode,
                  )
                }
              />
              <TextField
                sx={{ width: '50%', margin: '8px 0px' }}
                fullWidth
                margin="dense"
                label={
                  <>
                    Ville <RequiredField />
                  </>
                }
                type="text"
                variant="standard"
                value={patient.city}
                onChange={(event) =>
                  handleChangePatient(
                    'city',
                    event.target.value.match(/^(\s|[aA-zZÜ-ü]+|-)*$/)
                      ? event.target.value
                      : patient.city,
                  )
                }
              />
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  inputProps={{ maxLength: 21 }}
                  margin="dense"
                  label={
                    <>
                      Numéro de sécurité sociale <RequiredField />
                    </>
                  }
                  type="text"
                  fullWidth
                  variant="standard"
                  value={patient.socialInsuranceNumber}
                  onChange={(event) =>
                    handleChangeSINumber(
                      event.target.value,
                      handleChangePatient,
                    )
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ style: { cursor: 'pointer' } }}
                  sx={{ cursor: 'pointer' }}
                  margin="dense"
                  label={<>Médecin traitant</>}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={
                    patient.doctor
                      ? `${patient.doctor.firstName} ${patient.doctor.userName}`
                      : ''
                  }
                  onClick={() => setDoctorModal(true)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  inputProps={{ maxLength: 17 }}
                  margin="dense"
                  label={
                    <>
                      Téléphone <RequiredField />
                    </>
                  }
                  type="text"
                  fullWidth
                  variant="standard"
                  value={patient.phone}
                  onChange={(event) => {
                    const value = event.target.value
                    setPatient({ ...patient, phone: value })
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {patient.phone !== '+33 ' && (
                  <TextField
                    inputProps={{ maxLength: 17 }}
                    margin="dense"
                    label="Téléphone"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={patient.phone1}
                    onChange={(event) => {
                      const value = event.target.value
                      setPatient({ ...patient, phone1: value })
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <FormControlLabel
              control={<Checkbox />}
              label="Patient en ALD"
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: '0',
                paddingTop: '15px',
                width: 'fit-content',
              }}
              checked={patient.ald === true}
              onChange={() => handleChangePatient('ald', !patient.ald)}
            />
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              paddingRight: '80px',
            }}
          >
            <IconButton onClick={handleSave} sx={{ alignSelf: 'right' }}>
              <SaveIcon
                sx={{
                  fontSize: '36px',
                  color: isCompleted ? '#40D172' : 'lightgrey',
                }}
              />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
      <SearchDoctorModal
        isOpen={doctorModal}
        setIsOpen={setDoctorModal}
        handleDoctorSelection={handleDoctorSelection}
        getDoctors={props.getDoctors}
        doctorType="FamilyDoctor"
        getDoctorAutocomplete={props.getDoctorAutocomplete}
        getCountries={props.getCountries}
        addDoctor={props.addDoctor}
      />
    </Dialog>
  )
}
